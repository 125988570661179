import React, { useEffect, useState, useCallback } from "react";
import "react-vis/dist/style.css";
import {
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Table,
  Collapse,
  Pagination,
  Tooltip,
} from "antd";
import axios from "axios";
import { CaretRightOutlined } from "@ant-design/icons";

import WeightDispute from "./WeightDispute";
import { formatDateTime } from "../../helpers/date";
import ServiceProviderTag from "../shared/ServiceProviderTag";
import TransactionFilters from "./TransactionFilters";
import { TransactionButtons } from "./TransactionButtons.js";
import TransactionList from "./TransactionList.js";

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/transaction", breadcrumbName: "Transaction" },
];

const table = [
  {
    title: "ID",
    dataIndex: "objectId",
  },
  {
    title: "User Email",
    dataIndex: "merchant",
    key: "enail",
    render: (text) => (
      <Tooltip title={ text?.user?.username || "-"}>
        {text?.user?.email || "-"}{" "}
      </Tooltip>
    ),
  },
  {
    title: "Order ID",
    dataIndex: "order",
    key: "orderId",
    render: (text, row) => text?.objectId || row.ref_no || "-",
  },
  {
    title: "Tracking No",
    dataIndex: "order",
    key: "trackingNo",
    render: (text) => text?.consign_no || "-",
  },
  {
    title: "Courier",
    dataIndex: "order",
    render: (text, row) =>
      text?.serviceProvider ? (
        <ServiceProviderTag serviceProviderKey={text.serviceProvider} />
      ) : (
        ""
      ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Method",
    dataIndex: "method",
  },
  {
    title: "Success At (YYYY-MM-DD)",
    dataIndex: "creditSuccessAt",
    render: (text) => (text ? formatDateTime(text) : "-"),
  },
  {
    title: "Order Created At (YYYY-MM-DD)",
    dataIndex: "order",
    key: "createdAt",
    render: (text) => (text?.createdAt ? formatDateTime(text.createdAt) : "-"),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    render: (text) => text || "-",
  },
  {
    title: "Reference",
    dataIndex: "reference",
    render: (text) => text || "-",
  },
  {
    title: "User Tagging",
    dataIndex: "merchant",
    key: "tagging",
    render: (text) => text?.user?.tagging || "-",
  },
];

const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 50 });
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    const params = {
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      ...(filters.tagging && { tagging: filters.tagging }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get("/api/transactions", { params });

    setTransactions(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchTransactions();
  }, [pagination.currentPage, pagination.limit, fetchTransactions]);

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Transaction Details</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
              style={{ margin: "10px 0", textAlign: "right" }}
              routes={routes}
            />
          </Col>
        </Row>
      </div>

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <TransactionFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>

      <div className="table mt-4">
        <Tabs defaultActiveKey="1" animated={true}>
          <Tabs.TabPane tab="All Transactions" key="all-transactions">
            <Row className="mt-2 mb-2" justify="space-between" align="middle">
              <TransactionButtons
                filters={{
                  ...(filters.startDate !== null && {
                    startDate: filters.startDate?.startOf("day")?.toISOString(),
                  }),
                  ...(filters.endDate !== null && {
                    endDate: filters.endDate?.endOf("day")?.toISOString(),
                  }),
                  ...(filters.search && {
                    searchField: filters.searchField,
                    search: filters.search,
                  }),
                  ...(filters.tagging && {
                    tagging: filters.tagging,
                  }),
                  pagination: pagination,
                }}
              />
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) => {
                    setPagination({ ...pagination, limit, currentPage });
                  }}
                  current={pagination.currentPage}
                />
              </Col>
            </Row>
            <Table
              className="table-section"
              columns={table}
              dataSource={transactions}
              pagination={false}
              scroll={{ x: true }}
              loading={isLoading}
            />

            <Row justify="end">
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) => {
                    setPagination({ ...pagination, limit, currentPage });
                  }}
                  current={pagination.currentPage}
                />
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Consignment Purchase" key="consignmentPurchase">
            <TransactionList
              globalFilters={filters}
              types="consignment_purchase"
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Weight Dispute" key="weightDispute">
            <WeightDispute
              globalFilters={filters}
              showButtons={{ refundWeightDispute: true }}
              onWeightDisputeRefunded={() => fetchTransactions()}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Weight Dispute Refund" key="weightDisputeRefund">
            <TransactionList
              globalFilters={filters}
              types="refund-weight-dispute"
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Top Up" key="TransactionList">
            <TransactionList globalFilters={filters} types="Top Up" />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Admin Manual" key="adminManual">
            <TransactionList globalFilters={filters} types="Admin Manual" />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Refund" key="refund">
            <TransactionList globalFilters={filters} types="refund" />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Cod Amendment" key="codAmendment">
            <TransactionList
              globalFilters={filters}
              types={[
                "Refund Cod Amendment",
                "Refund NDR Amendment",
                "Charge Cod Amendment",
                "Charge NDR Amendment",
              ]}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="COD Refund" key="codRefund">
            <TransactionList
              globalFilters={filters}
              types="refund-cod-charge"
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="NDR Refund" key="ndrRefund">
            <TransactionList
              globalFilters={filters}
              types="refund-next-day-remittance-charge"
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Return Fee Refund" key="returnFeeRefund">
            <TransactionList
              globalFilters={filters}
              types="Refund Return Fee"
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Discount" key="discount">
            <TransactionList globalFilters={filters} types="Discount" />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Referral Program" key="refereeProgram">
            <TransactionList
              globalFilters={filters}
              types={["Referral Bonus", "Referee Bonus", "Top up Commission"]}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Transaction;
